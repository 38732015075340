<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p
              class="user-name font-weight-bolder mb-0"
              v-text="userName"
            />
            <span
              class="user-status"
              v-text="companyName"
            />
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :text="avatarText(userName)"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <div
          v-for="(company, index) in companyList"
          :key="(index + 1)"
        >
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="chooseCompany(company.id)"
          >
            <feather-icon
              size="16"
              icon="KeyIcon"
              class="mr-50"
            />
            <span>{{ company.name }}</span>
          </b-dropdown-item>
        </div>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push({ name: 'account-edit' })"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="clickLogout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import Vue from 'vue'
import { logout } from '@/auth/sanctum'
import router from '@/router'
import axios from '@/libs/axios'
import handleError from '@/views/components/errorHandler'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  setup() {
    return {
      avatarText,
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    userName() {
      if (Vue.prototype.$profile().data.user) {
        return Vue.prototype.$profile().data.user.name
      }
      return ''
    },
    companyList() {
      if (Vue.prototype.$profile().data.user) {
        return Vue.prototype.$profile().data.user.companies
      }
      return ''
    },
    companyName() {
      if (Vue.prototype.$activeCompany().data.company) {
        return Vue.prototype.$activeCompany().data.company.name
      }
      return ''
    },
  },
  methods: {
    clickLogout() {
      logout()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Logout successful',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
      router.push({ name: 'login' })
    },
    chooseCompany(id) {
      if (id === this.$activeCompany().data.company.id) {
        // Already logged into the same company
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'You are already logged into this company.',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      } else {
        axios.get(`/v1/companies/switch/${id}`)
          .then(response => {
            this.$activeCompany().data.company = response.data.activeCompany
            useJwt.setActiveCompany(response.data.activeCompany)
            this.$profile().fetchProfile()
            this.$permissions().fetchAccess()
            window.location.href = '/shipments' // Forces a refresh as well (need to reload all permissions and everything.)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Switched companies',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            handleError(error, this.$toast)
          })
      }
    },
  },
}
</script>
<style lang="scss">
.dropdown-menu {
  overflow-y: auto;
  max-height: calc(100vh - 100px);
}
</style>
